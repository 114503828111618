import Head from 'next/head'
import getConfig from 'next/config'
import { AppType } from 'next/dist/shared/lib/utils'
import { inspect } from '@xstate/inspect'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { Cartigan } from '../components/Cartigan'
import { PostHog } from '../components/PostHog'
import { currentCampaign } from '../content/campaigns'

import '../styles/index.css'

if (
  typeof window !== 'undefined' &&
  process.env.NODE_ENV === 'development' &&
  sessionStorage.getItem('swagleft-xstate-inspect') === 'true'
)
  inspect({ iframe: false })

const SwagLeftApp: AppType = ({ Component, pageProps }): JSX.Element => {
  const description =
    'SwagLeft works with artists, progressive causes & you to raise money and do some good.'
  const campaign = currentCampaign()
  const socialShareImage = new URL(
    campaign.socialShareImage.url,
    (process.env.NODE_ENV === 'development' &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (getConfig()?.publicRuntimeConfig?.BASE_URL as string)) ||
      'https://www.swagleft.com/'
  ).toString()

  const apiBaseUrl = process.env.NEXT_PUBLIC_CARTIGAN_BASE_URL
  const cartiganId = process.env.NEXT_PUBLIC_CARTIGAN_ID

  if (!apiBaseUrl)
    throw new Error('Missing config NEXT_PUBLIC_CARTIGAN_BASE_URL!')
  if (!cartiganId) throw new Error('Missing config NEXT_PUBLIC_CARTIGAN_ID!')

  const cartiganOptions = {
    apiBaseUrl,
    cartiganId,
    checkoutImageUrl: '/images/Logo.svg',
  }

  return (
    <ErrorBoundary>
      <Cartigan options={cartiganOptions}>
        <Head>
          <meta name="description" content={description} />
          <meta name="og:image" content={socialShareImage} />
          <meta name="og:image:width" content="3600" />
          <meta name="og:image:height" content="2407" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@swag_left" />
          <meta name="twitter:title" content="SwagLeft" />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={socialShareImage} />
        </Head>
        <PostHog>
          <Component {...pageProps} />
        </PostHog>
      </Cartigan>
    </ErrorBoundary>
  )
}

export default SwagLeftApp
