import { Campaign } from '../src/types'

import choicesQuoteAuthorImage from '~public/images/campaigns/choices/quote-author.jpg'
import choicesCauseImage from '~public/images/campaigns/choices/cause.jpg'
import choicesArtistImage from '~public/images/campaigns/choices/artist.jpg'
import choicesInstagram01 from '~public/images/campaigns/choices/instagram/instagram-01.jpg'
import choicesInstagram02 from '~public/images/campaigns/choices/instagram/instagram-02.jpg'
import choicesInstagram03 from '~public/images/campaigns/choices/instagram/instagram-03.jpg'
import choicesInstagram04 from '~public/images/campaigns/choices/instagram/instagram-04.jpg'
import choicesInstagram05 from '~public/images/campaigns/choices/instagram/instagram-05.jpg'
import choicesInstagram06 from '~public/images/campaigns/choices/instagram/instagram-06.jpg'
import choicesInstagram07 from '~public/images/campaigns/choices/instagram/instagram-07.jpg'
import choicesInstagram08 from '~public/images/campaigns/choices/instagram/instagram-08.jpg'

import miracleQuoteAuthorImage from '~public/images/campaigns/miracle/quote-author.jpg'
import miracleCauseImage from '~public/images/campaigns/miracle/cause.jpg'
import miracleArtistImage from '~public/images/campaigns/miracle/artist.jpg'
import miracleInstagramBrandiLynn from '~public/images/campaigns/miracle/instagram/instagram-brandi-lynn.jpg'
import miracleInstagramCadence from '~public/images/campaigns/miracle/instagram/instagram-cadence.jpg'
import miracleInstagramCooper from '~public/images/campaigns/miracle/instagram/instagram-cooper.jpg'
import miracleInstagramCourn from '~public/images/campaigns/miracle/instagram/instagram-courn.jpg'
import miracleInstagramEmily from '~public/images/campaigns/miracle/instagram/instagram-emily.jpg'
import miracleInstagramKim from '~public/images/campaigns/miracle/instagram/instagram-kim.jpg'
import miracleInstagramMeg from '~public/images/campaigns/miracle/instagram/instagram-meg.jpg'
import miracleInstagramMichelle from '~public/images/campaigns/miracle/instagram/instagram-michelle.jpg'

import organizeQuoteAuthorImage from '~public/images/campaigns/organize/quote-author.jpg'
import organizeArtistImage from '~public/images/campaigns/organize/artist.jpg'
import organizeCauseImage from '~public/images/campaigns/organize/cause.jpg'
import organizeInstagram01 from '~public/images/campaigns/organize/instagram/instagram-01.jpg'
import organizeInstagramCooper from '~public/images/campaigns/organize/instagram/instagram-cooper.jpg'
import organizeInstagramKim from '~public/images/campaigns/organize/instagram/instagram-kim.jpg'
import organizeInstagram04 from '~public/images/campaigns/organize/instagram/instagram-04.jpg'

import veryAsianQuoteAuthorImage from '~public/images/campaigns/very-asian/quote-author.jpg'
import veryAsianArtistImage from '~public/images/campaigns/very-asian/artist.jpg'
import veryAsianCauseImage from '~public/images/campaigns/very-asian/cause.jpg'
import veryAsianInstagramAjit from '~public/images/campaigns/very-asian/instagram/instagram-ajit.jpg'
import veryAsianInstagramEmily from '~public/images/campaigns/very-asian/instagram/instagram-emily.jpg'
import veryAsianInstagramHailey from '~public/images/campaigns/very-asian/instagram/instagram-hailey.jpg'
import veryAsianInstagramKim from '~public/images/campaigns/very-asian/instagram/instagram-kim.jpg'
import veryAsianInstagramMichelle from '~public/images/campaigns/very-asian/instagram/instagram-michelle.jpg'
import veryAsianInstagramOly from '~public/images/campaigns/very-asian/instagram/instagram-oly.jpg'
import veryAsianInstagramRayAndRobbie from '~public/images/campaigns/very-asian/instagram/instagram-ray-and-robbie.jpg'
import veryAsianInstagramShagun from '~public/images/campaigns/very-asian/instagram/instagram-shagun.jpg'

export const campaigns: Campaign[] = [
  {
    slug: 'choices-by-george-mccalman',
    heroImage: {
      url: '/images/campaigns/choices/hero-bg.jpg',
      alt: 'TK',
    },
    heroForegroundTone: 'dark',
    socialShareImage: {
      url: '/images/campaigns/choices/social-share.webp',
      alt: 'TK',
    },
    tripleImage: {
      url: '/images/campaigns/choices/flat-tee.jpg',
      alt: 'TK',
    },
    wraparoundImage: {
      url: '/images/campaigns/choices/folded-tee-narrow.jpg',
      alt: 'TK',
    },
    instagridImages: [
      choicesInstagram01,
      choicesInstagram02,
      choicesInstagram03,
      choicesInstagram04,
      choicesInstagram05,
      choicesInstagram06,
      choicesInstagram07,
      choicesInstagram08,
    ].map((image) => ({ url: image.src, alt: 'TK' })),
    offer: {
      description: `
      Super soft, lightweight tee in the best pink 
      with bright aqua screen print 
      of George McCalman’s hand-lettered CHOICES design 
      will show the world that you have made your choice—and
      you’re firmly on the side of reproductive justice for all.
      `,
      itemOffered: {
        name: 'Choices Tee',
        image: [
          {
            url: '/images/campaigns/choices/flat-tee.jpg',
            alt: 'TK',
          },

          {
            url: '/images/campaigns/choices/folded-tee.jpg',
            alt: 'TK',
          },
        ],
        cartImage: {
          url: '/images/campaigns/choices/product-cart.jpg',
          alt: 'TK',
        },
        sku: 'SLAFLC01001',
        sizes: [
          { name: 'xx-small', id: 'XXS' },
          { name: 'x-small', id: 'XS' },
          { name: 'small', id: 'S' },
          { name: 'medium', id: 'M' },
          { name: 'large', id: 'L' },
          { name: 'x-large', id: 'XL' },
          { name: 'xx-large', id: 'XXL' },
        ],
        materials: [
          '100% Airlume combed and ring-spun cotton',
          'Super soft & summer weight',
          'Gender neutral sizing',
        ],
      },
      price: 28,
      priceCurrency: 'USD',
    },
    donation: {
      name: 'Donation to National Network of Abortion Funds',
      sku: 'SLAFXX00001',
      image: [],
      cartImage: { url: '/images/campaigns/choices/cause-cart.jpg', alt: 'TK' },
      pricePresets: [
        { price: 10, priceCurrency: 'USD' },
        { price: 20, priceCurrency: 'USD' },
        { price: 50, priceCurrency: 'USD' },
        { price: 100, priceCurrency: 'USD' },
        { price: 250, priceCurrency: 'USD' },
      ],
    },
    heroText: 'My body\nMy future',
    donationCall: `Give what you can to help The National Network of Abortion Funds remove financial and logistical barriers to abortion for all who need it.`,
    runningTotal:
      "So far we've raised over $990 with an average donation of $30!",
    // eslint-disable-next-line no-irregular-whitespace
    callToAction: `With Roe v. Wade overturned, abortion will likely become illegal in 26 states. The time to act is now.`,

    artist: {
      name: 'George McCalman',
      descriptionHtml: `George McCalman is an artist and creative director
      based in San Francisco. His design studio McCalmanCo collaborates
      with a wide range of cultural clients.
      He is a culture columnist for the San Francisco Chronicle.
      His first book
      <a href='https://www.harpercollins.com/products/illustrated-black-history-george-mccalman?variant=39981632782370'><cite>Illustrated Black History</cite></a>
      is out now.`,
      linkUrl: 'https://mccalman.co/',
      linkText: 'mccalman.co',
      imageSrc: choicesArtistImage,
      imageAlt: 'TK',
    },

    cause: {
      name: 'AbortionFunds.org',
      descriptionHtml: `George chose National Network of Abortion Funds (NNAF)
        as the focus of his design and fundraising effort.
        NNAF works tirelessly to remove logical and financial barriers
        to abortion. They’re at the forefront of ensuring abortion access
        in the wake of this devastating decision
        and rely on the support from all of us to fund their work.`,
      linkUrl: 'http://abortionfunds.org/',
      linkText: 'abortionfunds.org',
      imageSrc: choicesCauseImage,
      imageAlt: 'TK',
      imageCreditUrl: 'https://www.instagram.com/tellier2some',
      imageCreditText: 'Christine Tellier',
    },
    quote: {
      text: 'Our North Star is a future living in a world where Reproductive Justice is everything we imagined it to be in OUR lifetimes AND for future generations.',
      avatar: {
        url: choicesQuoteAuthorImage.src,
        alt: '',
      },
      authorName: 'Oriaku Njoku',
      authorDescription:
        'EXECUTIVE DIRECTOR,\nNATIONAL NETWORK OF ABORTION FUNDS',
    },
    checkoutText: {
      addressFormAdditionalHTML: `
        <p>Your name, email and donation amount (if applicable)
        will be shared with The National Network of Abortion Funds (NNAF)
        and you are consenting to receive email from NNAF.
        You may unsubscribe at any time.</p>`,
    },
  },

  {
    slug: 'miracle-by-cami-zea',
    heroImage: {
      url: '/images/campaigns/miracle/hero-bg.jpg',
      alt: `
        The photo shows Angel Flores standing in front of a rainbow
        colored building, under a sign that says "Cute." She's smiling and
        twirling her hair. She is wearing the "Trans Lives Are a Miracle"
        t-shirt, a pink neck scarf and shiny shorts.
      `,
    },
    heroForegroundTone: 'light',
    heroCaptionHtml: `
      <a href='https://www.instagram.com/arkangeljoy/'>Angel Joy Flores</a>
      (she/they) from Queer Eye S6
      <br class='md:hidden'/>
      at <a href='https://www.cutenailstudio.com/'>Cute Nail Studio</a>
      in Austin, TX
    `,
    socialShareImage: {
      url: '/images/campaigns/miracle/social-share.webp',
      alt: `
        The photo shows Angel Flores standing in front of a rainbow
        colored building, under a sign that says "Cute." She's smiling and
        twirling her hair. She is wearing the "Trans Lives Are a Miracle"
        t-shirt, a pink neck scarf and shiny shorts.
      `,
    },
    tripleImage: {
      url: '/images/campaigns/miracle/flat-tee.jpg',
      alt: `
        A photo of the "Trans Lives Are a Miracle" t-shirt
        laid out flat on a white background. The shirt is
        a light, bright green and the wording is in royal blue.
      `,
    },
    wraparoundImage: {
      url: '/images/campaigns/miracle/folded-tee-narrow.jpg',
      alt: `
        A photo of the "Trans Lives Are a Miracle" t-shirt
        laid out flat on a white background.
        The shirt is a light, bright green
        and the wording is in royal blue.
      `,
    },
    instagridImages: [
      {
        url: miracleInstagramMeg.src,
        alt: 'Headshot of Meg (they/them) in their "Trans Lives Are a Miracle" t-shirt.',
      },
      {
        url: miracleInstagramCourn.src,
        alt: 'Headshot of Courn (she/they) in her "Trans Lives Are a Miracle" t-shirt.',
      },
      {
        url: miracleInstagramCooper.src,
        alt: 'Headshot of Cooper (she/her) in her "Trans Lives Are a Miracle" t-shirt.',
      },
      {
        url: miracleInstagramKim.src,
        alt: 'Headshot of Kim (she/her) in her "Trans Lives Are a Miracle" t-shirt.',
      },
      {
        url: miracleInstagramCadence.src,
        alt: 'Headshot of Cadence (they/them) in their "Trans Lives Are a Miracle" t-shirt.',
      },
      {
        url: miracleInstagramEmily.src,
        alt: 'Headshot of Emily (she/her) in her "Trans Lives Are a Miracle" t-shirt.',
      },
      {
        url: miracleInstagramMichelle.src,
        alt: 'Headshot of Michelle (she/her) in her "Trans Lives Are a Miracle" t-shirt.',
      },
      {
        url: miracleInstagramBrandiLynn.src,
        alt: 'Headshot of BrandiLynn (she/her) in her "Trans Lives Are a Miracle" t-shirt.',
      },
    ],
    offer: {
      description: `
      Lightweight, super soft, 100% cotton tee in super-fun lime with
      a royal blue screen print of Cami Zea’s hand-lettered MIRACLE design
      will convey to all who see it that you proudly support
      the lives, rights, dreams and joy of the transgender community.
      `,
      itemOffered: {
        name: 'MIRACLE Tee',
        image: [
          {
            url: '/images/campaigns/miracle/flat-tee-square.jpg',
            alt: `
              A photo of the "Trans Lives Are a Miracle" t-shirt
              laid out flat on a white background.
              The shirt is a light, bright green
              and the wording is in royal blue.
            `,
          },

          {
            url: '/images/campaigns/miracle/folded-tee-square.jpg',
            alt: `
              A photo of the "Trans Lives Are a Miracle" t-shirt
              on a white background. The tee has been folded
              to show the design. The shirt is a light, bright green
              and the wording is in royal blue.
            `,
          },
        ],
        cartImage: {
          url: '/images/campaigns/miracle/product-cart.jpg',
          alt: 'TK',
        },
        sku: 'SLTECZ02', // SwagLeft / Trans Equality / Cami Zea / Product Number
        sizes: [
          { name: 'x-small', id: 'XS' },
          { name: 'small', id: 'S' },
          { name: 'medium', id: 'M' },
          { name: 'large', id: 'L' },
          { name: 'x-large', id: 'XL' },
          { name: 'xx-large', id: 'XXL' },
          { name: 'xxx-large', id: 'XXXL' },
        ],
        materials: [
          '100% Airlume combed and ring-spun cotton',
          'Super soft & light weight',
          'Gender neutral sizing',
        ],
      },
      price: 29,
      priceCurrency: 'USD',
    },
    donation: {
      name: 'Donation to the National Center for Trans Equality Action Fund',
      sku: 'SLTEXX02', // SwagLeft / Trans Equality / Donation / Product Number
      image: [],
      cartImage: { url: '/images/campaigns/miracle/cause-cart.jpg', alt: 'TK' },
      pricePresets: [
        { price: 10, priceCurrency: 'USD' },
        { price: 20, priceCurrency: 'USD' },
        { price: 50, priceCurrency: 'USD' },
        { price: 100, priceCurrency: 'USD' },
        { price: 250, priceCurrency: 'USD' },
      ],
    },
    heroText: 'Celebrate\nTrans Joy',
    donationCall: `Your donation supports the National Center for Transgender Equality Action Fund in addressing the urgent need to protect transgender people and children from unjust legislation.`,
    runningTotal:
      'So far we’ve raised over $800 with an average donation of $25!',
    callToAction: `
      Trans people deserve to thrive and live without fear.
      Let’s create the world we want to see.
    `,

    artist: {
      name: 'Cami Zea',
      descriptionHtml: `
        Cami (she/her) is a hand-lettering artist, writer and illustrator.
        She focuses on messages of social justice, mental health,
        and the overwhelming amount of feelings that come with being a human.
        She is a fierce advocate for LGBTQIA rights and is proud
        to fundraise for and support the transgender community with her work.`,
      linkUrl: 'https://www.zeaink.com/',
      linkText: 'ZeaInk.com',
      imageSrc: miracleArtistImage,
      imageAlt: `
        This image is a headshot of Cami Zea (she/her).
        She is smiling slightly and wearing her
        “Trans Lives Are a Miracle” t-shirt.
      `,
    },

    cause: {
      name: 'NCTE Action Fund',
      descriptionHtml: `
        Cami chose the National Center for Transgender Equality Action Fund
        as the focus of her fundraising efforts. NCTE Action Fund builds power
        for transgender people, their families, and their allies—to make
        their collective voice heard—so that together, we can change the
        landscape in this country to fully support transgender equality.
      `,
      linkUrl: 'https://ncteactionfund.org/',
      linkText: 'NCTEActionFund.org',
      imageSrc: miracleCauseImage,
      imageAlt: `
        This image is of a protest for the rights of transgender people
        at the Texas state capitol. A person is holding up transgender
        pride flag.
      `,
      imageCreditUrl: 'https://www.instagram.com/mikeatx79/',
      imageCreditText: 'Michael Haertel',
    },
    quote: {
      text: `
        The beauty and vibrancy of the trans community
        cannot be stamped out by hateful politicians
        looking to score political points.
      `,
      avatar: {
        url: miracleQuoteAuthorImage.src,
        alt: `
          Headshot of Rodrigo Heng-Lehtinen.
          Rodrigo is standing in front of a green hedge.
        `,
      },
      authorName: 'Rodrigo Heng-Lehtinen',
      authorDescription:
        'EXECUTIVE DIRECTOR, NATIONAL CENTER FOR TRANSGENDER EQUALITY',
    },
    checkoutText: {
      addressFormAdditionalHTML: `
        <p>
          If your purchase includes a donation,
          your name, email and donation amount will be shared with
          the National Center for Transgender Equality Action Fund (NCTEAF)
          and you are consenting to receive email from NCTEAF.
          You may unsubscribe at any time.
        </p>
      `,
    },
  },

  {
    slug: 'organize-by-kelly-malka',
    heroText: 'There’s no place\nlike home.',
    heroImage: {
      url: '/images/campaigns/organize/hero-bg.jpg',
      alt: `
        Model standing in front of a tall tree.
        She is looking up into the leaves and wearing the organize tee.
      `,
    },
    heroForegroundTone: 'light',
    socialShareImage: {
      url: '/images/campaigns/organize/social-share.webp',
      alt: `
        Model standing in front of a tall tree.
        She is looking up into the leaves and wearing the organize tee.
      `,
    },
    tripleImage: {
      url: '/images/campaigns/organize/flat-tee.jpg',
      alt: `
        Off-white t-shirt with green text laid flat.
        The design says “No more fossil fuels” in an arch
        and then underneath it reads “Organize, Strike, Vote.”
        There is an illustration of the earth, sun and a daisy also.
      `,
    },
    wraparoundImage: {
      url: '/images/campaigns/organize/folded-tee-narrow.jpg',
      alt: `
        Off-white t-shirt with green text folded into a rectangle.
        The design says “No more fossil fuels” in an arch
        and then underneath it reads “Organize, Strike, Vote.”
        There is an illustration of the earth, sun and a daisy also.
      `,
    },
    instagridImages: [
      {
        url: organizeInstagram01.src,
        alt: 'A photo of a young girl wearing her organize t-shirt. She’s in front of a hedge and smiling.',
      },
      {
        url: organizeInstagramCooper.src,
        alt: 'A photo of Cooper wearing her organize t-shirt. She’s smiling and standing in front of a blue fence.',
      },
      {
        url: organizeInstagramKim.src,
        alt: 'A photo of Kim wearing her organize t-shirt and a red and purple puffy jacket. She’s smiling and in front of a hedge.',
      },
      {
        url: organizeInstagram04.src,
        alt: 'A photo of a young person wearing their organize t-shirt. They’re smiling and standing in front of a hedge.',
      },
    ],
    offer: {
      description: `
        Lightweight, super soft, 100% cotton tee in vintage white
        with a light and bold green screen print of
        Kelly Malka’s retro feel ORGANIZE design.
      `,
      itemOffered: {
        name: 'ORGANIZE Tee',
        image: [
          {
            url: '/images/campaigns/organize/flat-tee-square.jpg',
            alt: `
              Off-white t-shirt with green text laid flat.
              The design says “No more fossil fuels” in an arch
              and then underneath it reads “Organize, Strike, Vote.”
              There is an illustration of the earth, sun and a daisy also.
            `,
          },

          {
            url: '/images/campaigns/organize/folded-tee-square.jpg',
            alt: `
              Off-white t-shirt with green text folded into a rectangle.
              The design says “No more fossil fuels” in an arch
              and then underneath it reads “Organize, Strike, Vote.”
              There is an illustration of the earth, sun and a daisy also.
            `,
          },
        ],
        cartImage: {
          url: '/images/campaigns/organize/product-cart.png',
          alt: 'TK',
        },
        sku: 'SLSMKM03', // SwagLeft / Sunrise Movement / Kelly Malka / Product #
        sizes: [
          { name: 'x-small', id: 'XS' },
          { name: 'small', id: 'S' },
          { name: 'medium', id: 'M' },
          { name: 'large', id: 'L' },
          { name: 'x-large', id: 'XL' },
          { name: 'xx-large', id: 'XXL' },
          { name: 'xxx-large', id: 'XXXL' },
          { name: 'kid small', id: 'KS' },
          { name: 'kid medium', id: 'KM' },
          { name: 'kid large', id: 'KL' },
          { name: 'kid x-large', id: 'KXL' },
        ],
        materials: [
          '100% Airlume combed and ring-spun cotton',
          'Super soft & light weight',
          'Gender neutral sizing',
        ],
      },
      price: 28,
      priceCurrency: 'USD',
    },
    donation: {
      name: 'Donation to Sunrise Movement',
      sku: 'SLSMXX03', // SwagLeft / Sunrise Movement / Donation / Product #
      image: [],
      cartImage: {
        url: '/images/campaigns/organize/cause-cart.png',
        alt: 'TK',
      },
      pricePresets: [
        { price: 10, priceCurrency: 'USD' },
        { price: 20, priceCurrency: 'USD' },
        { price: 50, priceCurrency: 'USD' },
        { price: 100, priceCurrency: 'USD' },
        { price: 250, priceCurrency: 'USD' },
      ],
    },
    donationCall: `
      Your donation supports Sunrise Movement’s Action Fund
      in boldly addressing the climate crisis
      and demanding a green future for us all.
    `,
    callToAction: `
      System change > Climate change — Green New Deal now!
    `.trim(),
    callToActionLarge: `
      System change > Climate change
      Green New Deal now!
    `.trim(),

    artist: {
      name: 'Kelly Malka',
      descriptionHtml: `
        Kelly Malka is an illustrator and designer
        living and working in Los Angeles.
        As a first generation American
        born into a strong Jewish Moroccan heritage,
        her culture inspires the colors, language and tradition
        that informs and inspires her artistic style.
        Kelly is an environmental advocate
        and thrilled to fundraise for a cause so near to her heart.
      `,
      linkUrl: 'http://kellymalka.com/',
      linkText: 'KellyMalka.com',
      imageSrc: organizeArtistImage,
      imageAlt: `
        Photo of artist Kelly Malka sitting on the floor,
        in front of some of her artwork.
      `,
    },
    cause: {
      name: 'Sunrise Movement',
      descriptionHtml: `
        Sunrise Movement is a youth movement to stop climate change
        and create millions of good jobs in the process.
        We’re building an army of young people
        to make climate change an urgent priority across America,
        end the corrupting influence of fossil fuel executives on our politics,
        and elect leaders who stand up for the health and well-being
        of all people.
      `,
      linkUrl: 'https://www.sunrisemovement.org/',
      linkText: 'SunriseMovement.org',
      imageSrc: organizeCauseImage,
      imageAlt: `
        Young people at a climate march, 
        carrying a banner that says “Green New Deal.”
      `,
      imageCreditUrl: 'https://www.sunrisemovement.org/',
      imageCreditText: 'Sunrise Movement',
    },
    quote: {
      text: `
        The role of young people in politics and movements throughout the years
        has been to hold the moral line, and not move from it one inch—and
        that is what we’re doing.
      `,
      avatar: {
        url: organizeQuoteAuthorImage.src,
        alt: `
          A headshot of Varshini Prakash where she is holding a microphone.
        `,
      },
      authorName: 'Varshini Prakash',
      authorDescription: `CO-FOUNDER & EXECUTIVE DIRECTOR,\nSUNRISE MOVEMENT`,
    },
    checkoutText: {
      addressFormAdditionalHTML: `
        <p>
          If your purchase includes a donation,
          your name, email and donation amount will be shared with
          Sunrise Movement
          and you are consenting to receive email from Sunrise Movement.
          You may unsubscribe at any time.
        </p>
      `,
    },
  },

  {
    slug: 'very-asian-by-courn-ahn',
    heroText: 'Wear your\ncommunal joy!',
    heroImage: {
      url: '/images/campaigns/very-asian/hero-bg.jpg',
      alt: `
        Jane (she/her) stands in front of Seattle’s Historic Chinatown Gate,
        holding an ube latte from
        <a href="https://www.hoodfamousbakeshop.com/">Hood Famous Cafe.</a>
        She is joyful and laughing. She’s wearing a motorcycle jacket,
        the Very Asian t-shirt and a frilly pink skirt.
      `,
    },
    heroForegroundTone: 'light',
    socialShareImage: {
      url: '/images/campaigns/very-asian/social-share.webp',
      alt: `
        Jane (she/her) stands in front of Seattle’s Historic Chinatown Gate,
        holding an ube latte from
        <a href="https://www.hoodfamousbakeshop.com/">Hood Famous Cafe.</a>
        She is joyful and laughing. She’s wearing a motorcycle jacket,
        the Very Asian t-shirt and a frilly pink skirt.
      `,
    },
    tripleImage: {
      url: '/images/campaigns/very-asian/flat-tee.jpg',
      alt: `
        Forest green t-shirt laid flat showing
        Courn Ahn’s Very Asian design in pink and orange.
        The design has fun cartoon drawings of people in motion
        with “Very” written above the drawings and “Asian” below.
      `,
    },
    wraparoundImage: {
      url: '/images/campaigns/very-asian/folded-tee-narrow.jpg',
      alt: `
        Forest green t-shirt folded into a rectangle showing
        Courn Ahn’s Very Asian design in pink and orange.
        The design has fun cartoon drawings of people in motion
        with “Very” written above the drawings and “Asian” below.
      `,
    },
    instagridImages: [
      {
        url: veryAsianInstagramEmily.src,
        alt: 'Emily (she/her) is wearing the Very Asian t-shirt. She’s laughing in front of a blue wall.',
      },
      {
        url: veryAsianInstagramMichelle.src,
        alt: 'Michelle (she/her) is wearing the Very Asian t-shirt. She’s smiling and standing outside with her hands in her pockets.',
      },
      {
        url: veryAsianInstagramHailey.src,
        alt: 'Hailey (she/her) is wearing the Very Asian t-shirt. She’s smiling and making a little heart with her index finger and thumb.',
      },
      {
        url: veryAsianInstagramAjit.src,
        alt: 'Ajit (he/him) is wearing the Very Asian t-shirt. He’s outside drinking coffee.',
      },
      {
        url: veryAsianInstagramKim.src,
        alt: 'Kim (she/her) is wearing the Very Asian t-shirt. She’s smiling and making a heart using both her hands.',
      },
      {
        url: veryAsianInstagramRayAndRobbie.src,
        alt: 'Ray and Robbie (both he/him) are twins and wearing the Very Asian t-shirt. They are sitting together outside and Robbie has his arm around Ray’s shoulder.',
      },
      {
        url: veryAsianInstagramShagun.src,
        alt: 'Shagun (she/her) is wearing the Very Asian t-shirt. She has a slight smile and is standing in front of a tan wall.',
      },
      {
        url: veryAsianInstagramOly.src,
        alt: 'Oly (she/her) is wearing the Very Asian t-shirt. She is smiling and sitting in a yellow and light blue chair outside.',
      },
    ],
    offer: {
      description: `
        Lightweight, super soft, 100% cotton tee in a saturated dark green
        with a vibrant pink and orange screen print of Courn Ahn’s
        VERY ASIAN design. This tee is all about the joy and resilience
        of the AAPI community.
      `,
      itemOffered: {
        name: 'VERY ASIAN Tee',
        image: [
          {
            url: '/images/campaigns/very-asian/flat-tee-square.jpg',
            alt: `
              Forest green t-shirt laid flat showing
              Courn Ahn’s Very Asian design in pink and orange.
              The design has fun cartoon drawings of people in motion
              with “Very” written above the drawings and “Asian” below.
            `,
          },

          {
            url: '/images/campaigns/very-asian/folded-tee-square.jpg',
            alt: `
              Forest green t-shirt folded into a rectangle showing
              Courn Ahn’s Very Asian design in pink and orange.
              The design has fun cartoon drawings of people in motion
              with “Very” written above the drawings and “Asian” below.
            `,
          },
        ],
        cartImage: {
          url: '/images/campaigns/very-asian/product-cart.png',
          alt: 'TK',
        },
        sku: 'SL18CA04', // SwagLeft / 18 Million / Courtney Ahn / 4th campaign
        sizes: [
          { name: 'x-small', id: 'XS' },
          { name: 'small', id: 'S' },
          { name: 'medium', id: 'M' },
          { name: 'large', id: 'L' },
          { name: 'x-large', id: 'XL' },
          { name: 'xx-large', id: 'XXL' },
          { name: 'xxx-large', id: 'XXXL' },
        ],
        materials: [
          '100% Airlume combed and ring-spun cotton',
          'Super soft & light weight',
          'Gender neutral sizing',
        ],
      },
      price: 28,
      priceCurrency: 'USD',
    },
    donation: {
      name: 'Donation to 18 Million Rising',
      sku: 'SL18XX04', // SwagLeft / 18 Million / Donation / 4th campaign
      image: [],
      cartImage: {
        url: '/images/campaigns/very-asian/cause-cart.png',
        alt: 'TK',
      },
      pricePresets: [
        { price: 10, priceCurrency: 'USD' },
        { price: 20, priceCurrency: 'USD' },
        { price: 50, priceCurrency: 'USD' },
        { price: 100, priceCurrency: 'USD' },
        { price: 250, priceCurrency: 'USD' },
      ],
    },
    donationCall: `
      Your donation supports 18 Million Rising
      so they can continue their important work of
      mobilizing the AAPI community to speak up
      against injustice and take action.
    `,
    runningTotal:
      "So far we've raised over $1,200 with an average donation of $20!",
    callToAction: `
      Asian Americans are politically underrepresented — let’s organize and change that!
    `,
    artist: {
      name: 'Courn Ahn',
      descriptionHtml: `
        Courn Ahn (they/she) is a queer Korean American
        and a multi-disciplinary designer.
        Courn prioritizes working with BIPOC, queer, and women-owned businesses
        and on projects that align with their core beliefs of anti-racism
        and intersectionality.
        Courn lives in the Pacific Northwest and spends their free time
        creating the
        <a href="https://www.instagram.com/candy.courn/" target="_blank">happiest home</a>
        and making joy-filled art.
      `,
      linkUrl: 'https://courtneyahndesign.com/',
      linkText: 'CourtneyAhnDesign.com',
      imageSrc: veryAsianArtistImage,
      imageAlt: `
        A headshot of artist Courn Ahn (they/she).
        She has her hand on her chin and is smiling.
        She’s wearing a dress with a strawberry pattern
        and is in standing in front of a wall of art.
      `,
    },
    cause: {
      name: '18 Million Rising',
      descriptionHtml: `
        18 Million Rising connects the power of Asian America
        to digital first organizing. Since 2012, 18 Million Rising's
        online and offline advocacy and cultural campaigns have
        highlighted the struggles of Asian American communities
        while celebrating our resilience.
        We mobilize community power through powerful campaigns,
        culture work, and skills training to fight injustice
        and create the future we all deserve.
      `,
      linkUrl: 'https://18millionrising.org/',
      linkText: '18MillionRising.org',
      imageSrc: veryAsianCauseImage,
      imageAlt: `
        The 18 Million Rising staff is standing together outside
        on a city street. They’re wearing winter coats
        and smiling together in front of a mural.
      `,
      imageCreditUrl: 'https://18millionrising.org/',
      imageCreditText: '18 Million Rising',
    },
    quote: {
      text: `
        The power of digital first organizing has strengthened
        our Asian American community while celebrating our resilience
        and contributing to our overall people's movements.
      `,
      avatar: {
        url: veryAsianQuoteAuthorImage.src,
        alt: `
          A headshot of Irma Shauf-Bajar (he/she/siya),
          the Executive Director of 18 Million Rising.
        `,
      },
      authorName: 'Irma Shauf-Bajar\n(he/she/siya)',
      authorDescription: `EXECUTIVE DIRECTOR,\n18 MILLION RISING`,
    },
    checkoutText: {
      addressFormAdditionalHTML: `
        <p>
          Your name, email, and donation amount (if applicable) 
          will be shared with 18 Million Rising and you are consenting 
          to receive email. You may unsubscribe at any time.
        </p>
      `,
    },
  },
]

export const currentCampaign = (): Campaign => {
  const slug = 'organize-by-kelly-malka'
  const campaign = getCampaignBySlug(slug)
  if (!campaign) throw new Error(`Current campaign not found! ${slug}`)
  return campaign
}

export const getCampaignBySlug = (slug: string): Campaign | undefined =>
  campaigns.find((campaign) => campaign.slug === slug)
