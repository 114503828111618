import * as React from 'react'
import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}
const SvgLogo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 256.92 30.04"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <defs>
      <style>{'.Logo_svg__cls-1{fill:currentColor}'}</style>
    </defs>
    <path
      d="M6.06 29a10.13 10.13 0 0 1-4.41-3.06A7.52 7.52 0 0 1 0 21v-.93h6.25v.67a3.39 3.39 0 0 0 1.65 3 8.19 8.19 0 0 0 4.61 1.1A9.3 9.3 0 0 0 17 24a2.49 2.49 0 0 0 1.39-2.22 2.64 2.64 0 0 0-.91-2.05 6.48 6.48 0 0 0-2.28-1.31c-.91-.33-2.17-.71-3.78-1.16a51.17 51.17 0 0 1-5.54-1.86 9.75 9.75 0 0 1-3.63-2.64A6.85 6.85 0 0 1 .72 8.15a6.74 6.74 0 0 1 3.21-6A15.58 15.58 0 0 1 12.47 0a14.49 14.49 0 0 1 8.21 2.13 7.19 7.19 0 0 1 3.2 6.4v.51h-6.09V8.7a2.88 2.88 0 0 0-1.46-2.54 7.37 7.37 0 0 0-3.95-.93A9.26 9.26 0 0 0 8.3 6a2.2 2.2 0 0 0-1.41 2 2.11 2.11 0 0 0 .84 1.67 7.09 7.09 0 0 0 2.12 1.14c.84.29 2 .65 3.59 1.08a50 50 0 0 1 5.68 1.83A10.5 10.5 0 0 1 23 16.35 6.7 6.7 0 0 1 24.55 21c0 3.09-1.1 5.38-3.32 6.84S16 30 12.17 30a17.33 17.33 0 0 1-6.11-1ZM26.49.54H33l4.19 17.84c.11.53.24 1.26.4 2.19s.24 1.55.27 1.86h.22c0-.31.11-.94.27-1.88s.29-1.67.4-2.17L43.06.54h7.39l4.23 17.84c.11.47.24 1.19.38 2.15s.24 1.59.3 1.9h.21c0-.28.11-.88.25-1.79s.28-1.67.42-2.26L60.55.54h5.67l-7.7 29h-6.63l-4.78-18.47a23.46 23.46 0 0 1-.46-2.31c-.14-.91-.22-1.51-.25-1.79h-.17c-.06.31-.16.94-.32 1.9s-.29 1.69-.4 2.2l-4.69 18.46h-6.63ZM75.39.54h7.68l11.33 29h-6.68L85.65 24H72.47l-2.07 5.53h-6.34Zm8.32 18.34L81 11.53c-.22-.59-.55-1.58-1-3l-.84-2.75h-.22l-.84 2.75c-.42 1.38-.75 2.37-1 3l-2.75 7.35ZM109.86 13.77h14.92v15.76h-4.23l-.55-3.21a13.28 13.28 0 0 1-4.27 2.83 14.88 14.88 0 0 1-5.45.89q-6.72 0-10.38-3.68T96.26 15q0-7.48 3.88-11.24t11-3.76A19.57 19.57 0 0 1 118 1.2a10.9 10.9 0 0 1 4.94 3.51 9 9 0 0 1 1.84 5.72h-6.34a4.37 4.37 0 0 0-2.09-3.78 8.74 8.74 0 0 0-5-1.42c-3 0-5.16.79-6.61 2.37s-2.18 3.85-2.18 6.8v1.27q0 4.65 2 6.91t6.15 2.26a9.34 9.34 0 0 0 5.66-1.63 5.27 5.27 0 0 0 2.28-4.41h-8.79ZM148.93 11.15h-9.24V7.96l-9.91 7.08 9.91 7.08v-3.2h9.24v-7.77zM153.93.52h6.12v23.67h15.64v5.32h-21.76ZM179.75.52h22.77v5.2h-16.65v6.38h14.79v5.2h-14.79v7h16.91v5.19h-23ZM206.83.52h21v5.2H213v7.15h13.31v5.19H213v11.45h-6.13ZM240.85 5.81h-9V.52h24.05v5.29h-9v23.7h-6.13Z"
      className="Logo_svg__cls-1"
    />
  </svg>
)
export default SvgLogo
