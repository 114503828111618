// noinspection JSUnresolvedLibraryURL

import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import Script from 'next/script'
import { useInterpret } from '@xstate/react'
import { cartMachine } from '../src/machines/cartMachine'
import { CartContext } from '../src/state/CartContext'

type Props = PropsWithChildren<{
  options: CartiganOptions
}>

export const Cartigan: FunctionComponent<Props> = ({ options, children }) => {
  const configureCartigan = () => window.CartiganJS.configure(options)
  const cartService = useInterpret(cartMachine)

  useEffect(() => {
    const setCartQuantity = (quantity: number) =>
      cartService.send({ type: 'SET_QUANTITY', quantity })
    const listener = (event: CartiganQuantityChangedEvent) =>
      setCartQuantity(event.detail.quantity)

    document.addEventListener('CGNQuantityChanged', listener)
    return () => document.removeEventListener('CGNQuantityChanged', listener)
  }, [cartService])

  const [stylesheetMedia, setStylesheetMedia] = useState<string>('print')
  useEffect(() => setStylesheetMedia('all'), [])

  const baseURL =
    process.env.NEXT_PUBLIC_CARTIGAN_ASSETS_BASE_URL ||
    'https://static.cartigan.com/build/'
  const stylesheetURL = new URL('bundle.css', baseURL).href
  const scriptURL = new URL('bundle.js', baseURL).href

  return (
    <CartContext.Provider value={{ cartService }}>
      <link rel="stylesheet" href={stylesheetURL} media={stylesheetMedia} />

      <Script
        src={scriptURL}
        strategy="afterInteractive"
        onLoad={configureCartigan}
      />

      {children}
    </CartContext.Provider>
  )
}
