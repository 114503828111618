import { clsx } from 'clsx'
import {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import Link from 'next/link'
import { CampaignContext } from '../src/state/CampaignContext'
import Logo from './images/Logo'
import { CartButton } from './CartButton'
import styles from './Header.module.css'

export type Props = PropsWithChildren<{
  /**
   * Makes the header a translucent overlay on top of the page body.
   */
  overlay?: boolean
}>

export const Header: FunctionComponent<Props> = ({ overlay, children }) => {
  const [isAtTop, setIsAtTop] = useState(true)

  // We used to use `react-use` for this, but it has a bug:
  // https://github.com/Codecademy/gamut/pull/1822
  useEffect(() => {
    const checkScroll = () => setIsAtTop(window?.scrollY === 0)
    checkScroll()
    document.addEventListener('scroll', checkScroll)
    return () => document.removeEventListener('scroll', checkScroll)
  })

  const translucent = overlay && isAtTop
  const campaign = useContext(CampaignContext)
  const { heroForegroundTone } = campaign

  const className = clsx(styles.header, {
    [styles.fixed]: overlay,
    [styles.translucent]: translucent,
    [styles.foregroundLight]: translucent && heroForegroundTone === 'light',
    [styles.foregroundDark]: translucent && heroForegroundTone === 'dark',
  })

  return (
    <header className={className}>
      <div className="flex flex-wrap justify-between items-stretch">
        <Link href="/" className={`${styles.logoLink} flex items-center`}>
          <Logo title="SwagLeft" />
        </Link>

        <div className={styles.links}>
          {children}

          <Link
            href="/shop"
            className={`${styles.shopLink} flex items-center button button-transparent`}
          >
            SHOP
          </Link>

          <CartButton />
        </div>
      </div>
    </header>
  )
}
