import * as React from 'react'
import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}
const SvgCart = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 32"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M25 31.941H0V6.206h25V31.94Zm-23.53-1.47h22.06V7.675H1.47V30.47Z" />
    <path d="M19.117 6.618h-1.47A5.115 5.115 0 0 0 12.5 1.47a5.115 5.115 0 0 0-5.147 5.147h-1.47A6.59 6.59 0 0 1 12.5 0a6.59 6.59 0 0 1 6.617 6.618Z" />
  </svg>
)
export default SvgCart
