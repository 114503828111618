import React, { FunctionComponent, useContext } from 'react'
import { useActor } from '@xstate/react'
import { CartContext } from '../src/state/CartContext'
import styles from './CartButton.module.css'
import CartImage from './images/Cart'

export const CartButton: FunctionComponent = () => {
  const { cartService } = useContext(CartContext)
  const [cartState] = useActor(cartService)

  return (
    <button
      title="Shopping Bag"
      type="button"
      data-cgn-cart="view"
      className={`unbutton ${styles.cartButton}`}
    >
      <CartImage />
      <span className="relative top-0.5 lg:top-1">
        {cartState.context.quantity || ''}
      </span>
    </button>
  )
}
