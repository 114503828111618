import React, { ErrorInfo, PropsWithChildren } from 'react'
import * as Sentry from '@sentry/nextjs'
import { ErrorPage } from './pages/ErrorPage'

type State = {
  hasError: boolean
}

/** Logs client-side errors to Sentry and renders an error page. */
export class ErrorBoundary extends React.Component<PropsWithChildren, State> {
  state: State = { hasError: false }

  static getDerivedStateFromError(): Partial<State> {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage statusCode={0} />
    }

    // Return the regular page if no error.
    return this.props.children
  }
}
