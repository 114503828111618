import { FunctionComponent } from 'react'

export const FavIcon: FunctionComponent = () => (
  <>
    <link rel="icon" href="/favicon.ico" sizes="any" />
    <link rel="icon" type="image/svg" href="/favicon.svg" />
    <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
    <link rel="manifest" href="/site.webmanifest" />
  </>
)
