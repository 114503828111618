import { FunctionComponent } from 'react'
import { ErrorProps } from 'next/error'
import Link from 'next/link'
import { Layout } from '../Layout'
import Logo from '../images/Logo'

/** Simple error page. */
export const ErrorPage: FunctionComponent<ErrorProps> = ({ statusCode }) => (
  <Layout header={<></>}>
    <div className="utility-content">
      <Logo width="65%" title="SwagLeft" />
      {statusCode === 404 ? (
        <>
          <h1 className="utility-heading">
            <strong>Oops!</strong>
            That page doesn&apos;t exist!
          </h1>

          <p className="with-links">
            <Link href="/">But this one does!</Link>
          </p>
        </>
      ) : (
        <>
          <h1 className="utility-heading">
            <strong>Uh-oh!</strong>
            Something&apos;s up!
          </h1>

          <p className="with-links">
            We&apos;re working on being back in business shortly!
          </p>
        </>
      )}
    </div>
  </Layout>
)
