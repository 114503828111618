/**
 * Machine for tracking Cartigan status.
 *
 * Note: This is not directly connected to {@link addToCartMachine}.
 * That machine makes requests to Cartigan, and Cartigan fires events
 * that this machine can track.
 */

import { assign, createMachine, InterpreterFrom } from 'xstate'
import { CartContext } from '../state/CartContext'

type LoadingContext = {
  quantity: undefined
}

type ActiveContext = {
  quantity: number
}

type CartContext = {
  quantity?: number
}

export type CartTypestate =
  | {
      value: 'loading'
      context: LoadingContext
    }
  | {
      value: 'active'
      context: ActiveContext
    }

type SetQuantityEvent = {
  type: 'SET_QUANTITY'
  quantity: number
}

export type CartEvent = SetQuantityEvent

export const cartMachine = createMachine<CartContext, CartEvent, CartTypestate>(
  {
    id: 'cart',
    initial: 'loading',
    context: { quantity: undefined } as CartContext,

    on: {
      SET_QUANTITY: {
        target: 'active',
        actions: assign<CartContext, SetQuantityEvent>({
          quantity: (context, event) => event.quantity,
        }),
      },
    },

    states: {
      loading: {},
      active: {},
    },

    predictableActionArguments: true,
  }
)

export type CartInterpreter = InterpreterFrom<typeof cartMachine>
