import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import Head from 'next/head'
import { Header } from './Header'
import { Footer } from './Footer'
import { FavIcon } from './FavIcon'

type Props = PropsWithChildren<{
  header?: ReactNode
  footer?: ReactNode
}>

export const Layout: FunctionComponent<Props> = ({
  header,
  footer,
  children,
}) => (
  <>
    <Head>
      <title>SwagLeft</title>
      <FavIcon />
      <meta
        name="theme-color"
        media="(prefers-color-scheme: light)"
        content="#e4db36"
      />
      <meta
        name="theme-color"
        media="(prefers-color-scheme: dark)"
        content="#022548"
      />
    </Head>

    <div className="min-h-screen flex flex-col">
      {header || <Header />}
      <div className="content-grid overflow-x-hidden flex-grow">{children}</div>
      {footer || <Footer />}
    </div>
  </>
)
