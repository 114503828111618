import { FunctionComponent } from 'react'
import Link from 'next/link'
import styles from './Footer.module.css'

export const Footer: FunctionComponent = () => {
  return (
    <footer className={`content-grid bg-accent py-s4 px-s3 ${styles.footer}`}>
      <div className="flex flex-wrap justify-between items-center gap-s4">
        <div className="stack-l gap-s1">
          <Link href="/shop" className={`link ${styles.shopLink}`}>
            SHOP
          </Link>
          <div className="stack-l gap-s_2 items-start text-f1">
            <Link href="/#about" className="link">
              About SwagLeft
            </Link>
            <Link href="/#campaign" className="link">
              The Artist + The Cause
            </Link>
            <Link href="/faq" className="link">
              Returns + Exchanges
            </Link>
            <a
              className="link"
              href="https://getterms.io/view/l2lYe/privacy/en-us"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
          <p>©SwagLeft 2023</p>
        </div>
      </div>
    </footer>
  )
}
